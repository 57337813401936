import {MigrationInterface, QueryRunner} from "typeorm";

export class AddRecordTable1708381240063 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<void> {
		await queryRunner.query(`
            CREATE TABLE IF NOT EXISTS "record" (
                "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL,
                "postId" integer NOT NULL,
                "base64" blob NOT NULL,
                FOREIGN KEY (postId) REFERENCES POST(id)
            );
        `);
	}

	public async down(queryRunner: QueryRunner): Promise<void> {
		await queryRunner.query(`
            DROP TABLE "record";
        `);
	}
}