import React from 'react';
import classes from './SegmentBtn.module.scss'

const SegmentBtn = ({labels, idx, setIdx}:Props) =>{

    return(
        <div className={["segment", classes.Segment].join(" ")}>
            {labels.map((l,i)=>(
                <div key={i} className="segment__item">
                    <input type="radio" className="segment__input" name="segment-a" checked={idx===i} onClick={()=>setIdx(i)}/>
                    <div className="segment__button">{l}</div>
                </div>

            ))}
        </div>
    )
}

interface Props{
    labels: string[];
    idx: number;
    setIdx: Function;
}

export default SegmentBtn;