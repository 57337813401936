import React, { useMemo } from 'react';
import { FaRegStar, FaStar } from 'react-icons/fa';
import classes from './Rate.module.scss';

const Rate = ({rate, setRate}:Props) =>{

    const stars = useMemo(()=>[...Array(5).keys()], [])

    return(
        <div className={classes.Rate}>
            {stars.map((s,i)=>{
                if(rate<=i){
                    return <FaRegStar key={i} className={classes.Star} onClick={()=>setRate(i+1)}/>
                }else{
                    return <FaStar key={i} className={classes.Star} onClick={()=>setRate(i+1)}/>
                }
            })}
        </div>
    );
}

interface Props{
    rate: number;
    setRate: Function;
}

export default Rate;