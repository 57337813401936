import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../05_redux/store';
import { Col, Page, Row } from 'react-onsenui';
import classes from './PostDetail.module.scss';
import Wave from '../../01_atoms/Wave';
import TopBar from '../../01_atoms/TopBar';
import { PostEntity } from '../../06_utils/db/entity/postEntity';
import { FaEdit, FaRegEye } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { FeelLikeIcon } from '../../01_atoms/FeelLike';
import Fab from '../../01_atoms/Fab';
import Emotion from '../../01_atoms/Emotion';
import moment from 'moment';

const PostDetail = () =>{
    const {t} = useTranslation();
    const navigate = useNavigate();
    const posts:PostEntity[] = useSelector((root:RootState)=>root.postSlice.postStore.posts);
    const { id } = useParams();

    const post = posts.find(p=>p.id === Number(id))!;

    const back = () =>{
        navigate('/');
    }

    const gotoHomework = (homeworkId) =>{
        navigate('/homework/'+homeworkId)
    }

    const gotoEdit = (postId) =>{
        navigate('/editPost/'+postId)
    }

    return(
        <Page>
            <TopBar fun={back} title={moment(post.postDate).format("YYYY-MM-DD HH:mm")}/> 
            <Wave invert/>
            <div className={classes.PostDetail}>
                <Row>
                    <Col className={classes.PostDetailDescr}>
                        <FeelLikeIcon feelLike={post.feelLike} color={undefined} />
                        {post?.comment}
                    </Col>
                </Row>
                <Row className={classes.PostDetailEmotions}>
                    {post.emotions?.map((e,i)=><Emotion key={i} emotion={e}/>)}
                </Row>
                <Row className={classes.PostDetailButtons}>
                    {post.homework && 
                        <Col className={classes.PostDetailButton}>
                            <Fab onClick={()=>gotoHomework(post.homework?.id)} title={t('postDetail.checkHomework')} floating={false}><FaRegEye/></Fab>
                        </Col>
                    }
                    <Col className={classes.PostDetailButton}>
                        <Fab onClick={()=>gotoEdit(post.id)} title={t('postDetail.editPost')} floating={false}><FaEdit /></Fab>
                    </Col>
                </Row>
            </div>
        </Page>
    )
}

export default PostDetail;