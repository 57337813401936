import {MigrationInterface, QueryRunner} from "typeorm";

export class AddPostTable1703416999000 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<void> {
		await queryRunner.query(`
            CREATE TABLE IF NOT EXISTS "post" (
                "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL,
                "objectId" varchar,
                "comment" varchar,
                "emotions" varchar,
                "feelLike" integer,
                "versionLocal" integer,
                "postDate" datetime,
                "homeworkId" integer,
                FOREIGN KEY (homeworkId) REFERENCES HOMEWORK(id)
            );
        `);
	}

	public async down(queryRunner: QueryRunner): Promise<void> {
		await queryRunner.query(`
            DROP TABLE "post";
        `);
	}
}