import axios, { AxiosRequestTransformer } from 'axios';
import moment from 'moment';

const replacer = function(this: any, key: string, value: any) {
  if (this[key] instanceof Date) {
    return {
      "__type": "Date",
      "iso": moment(this[key]).toJSON()
    }
  }    
  return value
}

const headers = {
  "Content-Type": "application/json",
  'X-Parse-Application-Id': 'collabothon',
  'X-Parse-Session-Token': JSON.parse(sessionStorage.getItem("user")||"{}")?.sessionToken
};

export default axios.create({
  baseURL: `https://www.polarny.it/parse/`,
  headers, 
  transformRequest: [(data, headers) => JSON.stringify(data, replacer), ...(axios.defaults.transformRequest as AxiosRequestTransformer[])]
});