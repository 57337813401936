import React, { useState } from 'react';
import { Carousel } from 'react-onsenui';
import WizardPage from '../../02_molecule/WizardPage';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { newHomework } from '../../05_redux/HomeworkSlice';
import store, { RootState } from '../../05_redux/store';
import { HomeworkEntity } from '../../06_utils/db/entity/homeworkEntity ';
import { useSelector } from 'react-redux';
import AddHomeworkManualy from './AddHomeworkManualy';
import HomeworkDate from './HomeworkDate';
import ImportHomework from './ImportHomework';


const NewHomeWorkWizard = () =>{
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = store.dispatch;
    const selectedKid = useSelector((root:RootState)=>root.statusSlice.status.selectedKid)

    const [ idx, setIdx ] = useState(0);
    const [title, setTitle] = useState("");
    const [descr, setDescr] = useState("");
    const [homeworkDate, setHomeworkDate] = useState(new Date( Date.now() + 6.048e+8 ));

    const saveEnable = title.length>3;

    const save = () =>{
        dispatch(newHomework({title, descr, homeworkDate} as HomeworkEntity, selectedKid))
        navigate("/homework")
    }

    const next = () => {
        setIdx(idx+1);
    }

    const back = () => {
        setIdx(idx-1);
    }

    const cancel = () => {
        navigate("/homework")
    }

    

    const pages = [
        ( 
            {title:t('newHomework.importTitle'), content:<ImportHomework />}
        ),( 
            {title:t('newHomework.manualTitle'),  content:<AddHomeworkManualy title={title} setTitle={setTitle} descr={descr} setDescr={setDescr} />}
        ),(
            {title:t('newHomework.homeworkDate'),  content:<HomeworkDate homeworkDate={homeworkDate} setHomeworkDate={setHomeworkDate}/>}
        )
    ]

    return(        
        <Carousel swipeable centered autoScroll fullscreen index={idx}> 
            {pages.map((p,i)=>(
                <WizardPage 
                    key={i}
                    save={save} 
                    next={next} 
                    back={back} 
                    setPage={setIdx}
                    saveEnable={saveEnable}
                    cancel={cancel}
                    wizardProgress={i/(pages.length-1)}
                    wizardMax={pages.length}
                    title={p.title}
                >
                    {p.content}
                </WizardPage>
            ))}
        </Carousel> 
    )
}

export default NewHomeWorkWizard;
