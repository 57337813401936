import React from "react";
import { Card } from "react-onsenui";
import 'moment-timezone';
import { FaRegCommentAlt, FaStar } from "react-icons/fa";
import { HomeworkEntity } from "../../06_utils/db/entity/homeworkEntity ";
import classes from './Homework.module.scss';
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Homework = ({homework}:Props) =>{
  const navigate = useNavigate();

  const gotoDetail = (id: number) => {
    navigate('/homework/' + id);
  }

  let tooltipColor = classes.ActiveHomework;
  if(homework.archived){
    tooltipColor = classes.DoneHomework;
  }else if(homework.homeworkDate<new Date()){
    tooltipColor = classes.OvertimeHomework;
  }

  return(
    <Card modifier="homework" onClick={()=>gotoDetail(homework.id)}>
        <div className={[classes.CornerTooltip, tooltipColor].join(' ')} />
        <div className={classes.CornerTooltipText}>{moment(homework.homeworkDate).format("YYYY-MM-DD")}</div>
        <div>
            <span className={classes.HomeworkTitle}>{homework.title}</span>
            {homework.post && homework.post.length>0 && <span className={classes.HomeworkPostsCount}>{homework.post.length}<FaRegCommentAlt/></span>}
            {homework.rating>0 && <span className={classes.HomeworkPostsCount}>{homework.rating}<FaStar/></span>}
        </div>
        <div className={classes.HomeworkDescr}>
            {homework.descr.length>130 ? homework.descr.substring(0,130)+"..." : homework.descr}
        </div>
    </Card>
  )
}

interface Props{
  homework: HomeworkEntity;
}

export default Homework;
