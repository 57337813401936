import React, { useEffect, useState } from 'react';
import { FaChild, FaCommentMedical, FaHouseUser } from 'react-icons/fa';
import { Button, Col, Row } from 'react-onsenui';
import '../app/App.scss';
import store, { RootState } from '../05_redux/store';
import { useSelector } from 'react-redux';
import PinSelector from '../01_atoms/PinSelector';
import { login, loginOffline } from '../05_redux/StatusSlice';
import { useTranslation } from 'react-i18next';

const Test = () =>{
  const {t} = useTranslation()
  const status = useSelector((root:RootState)=>root.statusSlice.status);
  const user = status.user
  const dispatch = store.dispatch;

  const [typedPin, setTypedPin] = useState([]as number[]);
  const [error, setError] = useState("")


  useEffect(()=>{
    if(typedPin.length >=4){
      dispatch(loginOffline(typedPin)).then(()=>{
        console.log("success")
      }).catch((e)=>{
        setError(t("login.pinIncorrect"))
      }).finally(()=>{
        setTypedPin([]);
      });
    }
  }, [typedPin, dispatch, t])
  
  if(status.isOnline && !user){
    return (
      <section>
        <Row>
          <Col className="text-center">
            <Button id='login-kid1' modifier='login' onClick={()=>dispatch(login("Mariolka Rydowicz", "test"))}><FaChild/>LOGIN kid1</Button>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button id='login-parent' modifier='login' onClick={()=>dispatch(login("Grzymisława Pipka", "parent"))}><FaHouseUser/>LOGIN parent</Button>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button id='login-kid2' modifier='login' onClick={()=>dispatch(login("Grzegorz Brzęczyszczykiewicz", "kid2"))}><FaChild/>LOGIN kid2</Button>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button id='login-terap1' modifier='login' onClick={()=>dispatch(login("Mściwoj Północny", "terap1"))}><FaCommentMedical/>LOGIN therapist</Button>
          </Col>
        </Row>
      </section>
    );

  }else{
    return(
      <section>
        <PinSelector title={t("login.pin")} value={typedPin} onChange={(v)=>setTypedPin([...typedPin, v])} randomize={true} error={error}/>
      </section>
    )
  }
}

export default Test;
