import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import store, { RootState } from '../../05_redux/store';
import { HomeworkEntity } from '../../06_utils/db/entity/homeworkEntity ';
import { Col, Page, Row } from 'react-onsenui';
import classes from './EndHomework.module.scss';
import Wave from '../../01_atoms/Wave';
import TopBar from '../../01_atoms/TopBar';
import Fab from '../../01_atoms/Fab';
import { FaFlagCheckered } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Rate from '../../01_atoms/Rate';
import { updateHomeworks } from '../../05_redux/HomeworkSlice';

const EndHomework = () =>{
    const {t} = useTranslation()
    const navigate = useNavigate();
    const homeworks:HomeworkEntity[] = useSelector((root:RootState)=>root.homeworkSlice.homeworkStore.homeworks);
    const { id } = useParams();
    const dispatch = store.dispatch;

    const homework = homeworks.find(h=>h.id === Number(id));

    const [rate, setRate] = useState(0);

    const back = () =>{
        navigate('/homework/'+homework?.id);
    }

    const endHomework = () =>{
        dispatch(updateHomeworks(
            homework!, 
            true,
            rate
        )).then(res=>{
            navigate('/homework/');
        });
    }

    return(
        <Page>
            <TopBar fun={back} title={homework?.title}/> 
            <Wave invert/>
            <div className={classes.EndHomework}>
                 <Row>
                    <Col className={classes.EndHomeworkDescr}>
                        {homework?.descr}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Rate rate={rate} setRate={setRate}/>
                    </Col>
                </Row>
                {/*<Row className={classes.HomeworkDetailPosts}>
                    <Col>
                        {homework?.post?.length === 0 && t("homeworkDetail.noPosts")}                    
                        {homework?.post?.map((pfh, i)=><Post key={i} post={pfh} editable={false}/>)}
                    </Col>
                </Row>*/}
                <Row className={classes.EndHomeworkButtons}>
                    <Col className={classes.EndHomeworkButton}>
                        <Fab 
                            id="goto-add-post-from-homework--btn" 
                            onClick={endHomework} 
                            title={t('endHomework.closeHomework')} 
                            floating={false}
                            disabled={rate===0}
                        ><FaFlagCheckered/></Fab>
                    </Col>
                </Row> 
            </div>
        </Page>
    )
}

export default EndHomework;