import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Fab, Tab, Tabbar } from "react-onsenui";
import Wall from "./Wall";
import HomeWork from "./HomeWorks";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Main = ({idx}: Props) =>{

    const [pageIdx, setPageIdx] = useState(idx);

    const navigate = useNavigate();
    const {t} = useTranslation();

    const addBtnAction = () =>{
        if(pageIdx === 1){
            navigate("/newhomework")
        }else{
            navigate("/newpost")
        }
    }

    return(
        <>
            <Tabbar
                onPreChange={({index}) => {setPageIdx(index)}}
                onPostChange={() => {}}
                onReactive={() => {}}
                position='bottom'
                index={pageIdx}
                renderTabs={(activeIndex, tabbar) => [
                {
                    content: <Wall />,
                    tab: <Tab label={t('menu.wall')} icon={"md-home"} />
                },
                {
                    content: <HomeWork />,
                    tab: <Tab label={t('menu.homework')} icon="md-settings" />
                }]
                }
            />
            <Fab id="goto-add-homework--btn" modifier="floating" onClick={addBtnAction}><FaPlus/></Fab>
        </>
    )
}

interface Props{
    idx: number;
}

export default Main;