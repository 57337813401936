import React from 'react';
import classes from './Pagination.module.scss';
import { FaArrowLeft, FaArrowRight, FaRegDotCircle } from 'react-icons/fa';
import { FaRegCircle } from 'react-icons/fa6';
import { Button, Col, Row } from 'react-onsenui';

const Pagination = ({progress, max, setPage, next, back}:Props) =>{

    const onClick = (i) => {
        setPage(i)
    }


    return(
        <Row className={classes.paginationContainer}>
            <Col className={classes.WizardPageBottomCol} width={15}>
                <Button modifier="quiet" onClick={back}>
                    <FaArrowLeft/>
                </Button>                
            </Col>
            <Col className={classes.WizardPageBottomCol} width={70}>
                {Array(max).fill(0).map((v,i)=>(
                    <span key={i} className={classes.paginationMark} onClick={()=>onClick(i)}>
                        {i===((max-1)*progress) ? <FaRegDotCircle/> : <FaRegCircle/>}
                    </span>
                ))}
            </Col>
            <Col className={classes.WizardPageBottomCol} width={15}>
                <Button modifier="quiet" onClick={next}>
                    <FaArrowRight/>
                </Button>
            </Col>
        </Row>
    )

}

interface Props{
    progress: number,
    max: number,
    setPage: Function,
    next: Function,
    back: Function
}

export default Pagination;