import { createSlice } from '@reduxjs/toolkit';
import Redux from 'redux';
import api from '../app/api';
import { HomeworkType, UserType } from '../app/types';
import store from './store';
import { setLoading } from './StatusSlice';
import HomeworkDataSource from '../06_utils/db/data-sources/HomeworkDataSource';
import { HomeworkEntity } from '../06_utils/db/entity/homeworkEntity ';
import { homework2HomeworkType, mergeHomeworks } from '../06_utils/mergeHomeworks';
import { Capacitor } from '@capacitor/core';
import sqliteConnection from '../06_utils/db/database';
import { posts2PostType } from '../06_utils/mergePosts';

export const HomeworkSlice = createSlice({
  name: 'userData',
  initialState: {
    homeworkStore: {
      homeworks:[] as HomeworkEntity[]
    },
  },
  reducers: {
    addHomework: ({homeworkStore}, {payload}) => {
      homeworkStore.homeworks.unshift(payload);
    },
    setHomeworks: ({homeworkStore}, {payload}) => {
      homeworkStore.homeworks = payload;
    },
    updateHomework: ({homeworkStore}, {payload}) => {
      const i = homeworkStore.homeworks.findIndex(h=>h.id === payload.id)
      homeworkStore.homeworks[i] = payload;
    }
  },
})

export const syncHomeworks = (selectedKid?:UserType)=>{
  return (dispatch:Redux.Dispatch) =>{
    return new Promise(async(resolve) =>{
      dispatch(setLoading(true));
      const userObjectId=store.getState().statusSlice.status.user.objectId;

      const localHomeworks:HomeworkEntity[] = await HomeworkDataSource
        .createQueryBuilder(HomeworkEntity, 'homework')
        .leftJoinAndSelect("homework.post", "post")
        .orderBy('homework.homeworkDate', 'DESC')
        .getMany();

      const where = selectedKid ? '&where={"$and":[{"archived":false},{"owner":{"__type":"Pointer","className":"_User", "objectId":"'+selectedKid.objectId+'"}}]}' : "";
      const remoteHomeworks:HomeworkType[] = (await api.get('/classes/Homework?order=-createdAt'+where)).data.results;

      await mergeHomeworks(localHomeworks, remoteHomeworks, userObjectId, dispatch);

      const updatedlocalHomeworks:HomeworkEntity[] = await HomeworkDataSource
      .createQueryBuilder(HomeworkEntity, 'homework')
      .leftJoinAndSelect("homework.post", "post")
      .orderBy('homework.homeworkDate', 'DESC')
      .getMany();
      
      dispatch(setHomeworks(updatedlocalHomeworks));
      dispatch(setLoading(false));
      resolve(updatedlocalHomeworks);
    });
  }
}

export const requestHomeworks = (selectedKid?:UserType)=>{
  return (dispatch:Redux.Dispatch) =>{
    return new Promise(async(resolve) =>{
      dispatch(setLoading(true));

      const localHomeworks:HomeworkEntity[] = (await HomeworkDataSource
        .createQueryBuilder(HomeworkEntity, 'homework')
        .leftJoinAndSelect("homework.post", "post")
        .orderBy('homework.homeworkDate', 'DESC')
        .getMany())
        .map(h=>({...h, post:posts2PostType(h.post||[])}));   

      dispatch(setHomeworks(localHomeworks));
      dispatch(setLoading(false));
      resolve(localHomeworks);
    });
  }
}

export const newHomework = (homework:HomeworkEntity, selectedKid:UserType) =>{
  return (dispatch:Redux.Dispatch) =>{
    return new Promise(async(resolve) =>{
      dispatch(setLoading(true));
      const userObjectId = selectedKid || store.getState().statusSlice.status.user.objectId;

      const connection = HomeworkDataSource;
      const database = connection.options.database;
      const homeworkRepository = connection.getRepository(HomeworkEntity);

      let savedHomework:HomeworkEntity = await homeworkRepository.save(homework);
      if (Capacitor.getPlatform() === 'web'&& typeof database === 'string') {        
        sqliteConnection.saveToStore(database);
      }

      if(store.getState().statusSlice.status.isOnline){
        await mergeHomeworks([savedHomework], [], userObjectId, dispatch);
      }
      savedHomework = await homeworkRepository.findOne({ where: { id: savedHomework.id } }) || savedHomework;
      dispatch(addHomework(savedHomework));
      dispatch(setLoading(false));
    });
  }
}

export const updateHomeworks = (homework:HomeworkEntity, archived:boolean, rating:number) =>{
  return (dispatch:Redux.Dispatch) =>{
    return new Promise(async(resolve) =>{
      const connection = HomeworkDataSource;
      const database = connection.options.database;
      const homeworkRepository = connection.getRepository(HomeworkEntity);

      await homeworkRepository.update( homework.id, {archived, rating});
      if (Capacitor.getPlatform() === 'web'&& typeof database === 'string') {        
        sqliteConnection.saveToStore(database);
      }
      if(store.getState().statusSlice.status.isOnline){
        await api.put('/classes/Homework/'+homework.objectId, {archived, rating});
      }
      const savedHomework = await homeworkRepository.findOne({ where: { id: homework.id } }) || homework;

      dispatch(updateHomework(homework2HomeworkType(savedHomework)));
      // dispatch(updateHomework({id:homework.id, archived, rating}));
      dispatch(setLoading(false));
      resolve(savedHomework);
    });
  }
}

export const { addHomework, setHomeworks, updateHomework } = HomeworkSlice.actions

export default HomeworkSlice.reducer
