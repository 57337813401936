import React from 'react';
import classes from './ImportHomework.module.scss';
import Underconstraction from '../../01_atoms/Underconstraction';

const ImportHomework = () =>{

    return(
        <div className={classes.commentContainer}>
            <Underconstraction/>
        </div>
    )
}

export default ImportHomework;