import React from "react";
import classes from './Separator.module.scss';

const Separator = ({title}:Props) =>{

    return(
        <div className={classes.Separator}>{title}</div>
    )
}

interface Props{
    title:string
}

export default Separator;