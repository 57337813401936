import React from 'react';
import classes from './Comment.module.scss'
import { useTranslation } from 'react-i18next';

const Comment = ({comment, setComment}: Props) =>{
    const {t} = useTranslation()

    return(
        <div className={classes.commentContainer}>
            <textarea
                id="comment-new-post--input"
                className="textarea"
                value={comment}
                rows="10"
                placeholder={t('newPost.commentPlaceholder')}
                onChange={(event) => { setComment(event.target.value)} }/>
        </div>
    )
}

interface Props{
    comment: string,
    setComment: Function
}

export default Comment;