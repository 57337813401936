import { Capacitor } from "@capacitor/core";
import api from "../app/api";
import { HomeworkType } from "../app/types";
import sqliteConnection from './db/database';
import { HomeworkEntity } from "./db/entity/homeworkEntity ";
import HomeworkDataSource from "./db/data-sources/HomeworkDataSource";
import { posts2PostType } from "./mergePosts";

export const mergeHomeworks = async(localHomeworks:HomeworkEntity[], remoteHomeworks:HomeworkType[], userObjectId:string, dispatch:Function):Promise<HomeworkType[]> =>{
    return new Promise(async (resolve) =>{
        const newLocalHomework:HomeworkType[] = [];
        const database = HomeworkDataSource.options.database;
        const homeworkRepository = HomeworkDataSource.getRepository(HomeworkEntity);

        //find homework that are local but are not on remote
        await localHomeworks.filter(p=>!p.objectId).forEach(async (p:HomeworkEntity) => {
            const ACL = {}
            ACL[userObjectId] = { "read": true, "write": true }
            const dataToSend:HomeworkType = {
                ...homework2HomeworkType(p), 
                ACL, 
                owner:{"__type":"Pointer",className:"_User",userObjectId}
            }
            const newRemoteHomeworkObjectId = (await api.post('/classes/Homework', dataToSend )).data.objectId;

            let homeworkToUpdate = await homeworkRepository.findOne({ where: { id: p.id } });
            if (homeworkToUpdate != null) {
                homeworkToUpdate.objectId = newRemoteHomeworkObjectId;
                await homeworkRepository.save(homeworkToUpdate);
            }

            if (Capacitor.getPlatform() === 'web'&& typeof database === 'string') {        
                await sqliteConnection.saveToStore(database);
            }
        });

        //find homework that are remote but not local
        const homeworksToSave:HomeworkEntity[] = []
        await remoteHomeworks.filter(rh=>!localHomeworks.find(lh=>lh.objectId === rh.objectId)).forEach(async (rh:HomeworkType)=>{
            const homework:HomeworkEntity = new HomeworkEntity(rh);
            homeworksToSave.push(homework);
            newLocalHomework.push(rh)       
        })
        
        if(homeworksToSave.length>0){
            await homeworkRepository.save(homeworksToSave);
            if (Capacitor.getPlatform() === 'web'&& typeof database === 'string') {        
                await sqliteConnection.saveToStore(database);
            }
        }

        resolve(newLocalHomework);
    })
}

export const homeworks2HomeworkType = (homeworks:HomeworkEntity[]):HomeworkType[] =>{
    return homeworks.map(p=>homework2HomeworkType(p));
}

export const homework2HomeworkType = (homework:HomeworkEntity):HomeworkType =>{
    return {
        id: homework.id,
        objectId: homework.objectId,
        title: homework.title,
        descr: homework.descr,
        rating: homework.rating,
        archived: homework.archived,
        homeworkDate: homework.homeworkDate,
        posts: posts2PostType(homework.post || [])
    }
}