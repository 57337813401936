import React from 'react';
import { GiBulldozer } from 'react-icons/gi';

const Underconstraction = () =>{

    return(
        <>        
            <div className='underconstructionIcon'><GiBulldozer /></div>
            <div className='underconstructionTitle'>underconstruction</div>
        </>
    )
}

export default Underconstraction;