import React from 'react';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "../05_redux/store";
import PinSelector from "../01_atoms/PinSelector";
import { setPinAndPersist } from "../05_redux/StatusSlice";
import { useTranslation } from "react-i18next";


const NewPin = () =>{
    const [oldPin, setOldPin] = useState([] as number[]);
    const [newPin, setNewPin] = useState([] as number[]);
    const [newPinRepeat, setNewPinRepeat] = useState([] as number[]);
    const [phase, setPhase] = useState(0);
    const [error, setError] = useState("");
    const {t} = useTranslation()

    const dispatch = store.dispatch;
    const status = useSelector((root:RootState)=>root.statusSlice.status);

    useEffect(()=>{
        if(!status.pin){
            setPhase(1);
        }
    },[status.pin])


    const oldPinChange = (v)=>{
        const pin = [...oldPin, v];
        if(pin.length>=4){
            setPhase(1)
            setOldPin([]);
        }else{
            setOldPin([...oldPin, v])
        }
    }

    const newPinChange = (v)=>{
        const pin = [...newPin, v];
        if(pin.length>=4){
            setPhase(2);
            setError("")
        }
        setNewPin(pin);
    }

    const newPinRepeatChange = (v)=>{
        const pin = [...newPinRepeat, v];
        if(pin.length>=4 && JSON.stringify(pin) === JSON.stringify(newPin)){
            dispatch(setPinAndPersist(pin))
            setPhase(0);
            setOldPin([]);
            setNewPin([]);
            setNewPinRepeat([]);
        }else if(pin.length>=4){
            setError(t('login.pinNotIdenticall'))
            setPhase(1);
            setNewPin([]);
            setNewPinRepeat([]);
        }else{
            setNewPinRepeat(pin)
        }
    }

    return(
        <div>
            {phase === 0 && <PinSelector title={t('login.pinOld')} value={oldPin} onChange={oldPinChange} randomize={true} error={error}/>}
            {phase === 1 && <PinSelector title={t('login.pinNew')} value={newPin} onChange={newPinChange} randomize={false} error={error}/>}
            {phase === 2 && <PinSelector title={t('login.pinRepeat')} value={newPinRepeat} onChange={newPinRepeatChange} randomize={false} error={error}/>}
        </div>
    )
}

export default NewPin;