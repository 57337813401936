import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-onsenui';
import WizardPage from '../../02_molecule/WizardPage';
import { useTranslation } from 'react-i18next';
import FeelLikeSelector from './FeelLikeSelector';
import Comment from './Comment';
import { useNavigate, useParams } from 'react-router-dom';
import { requestHomeworks } from '../../05_redux/HomeworkSlice';
import { newPost } from '../../05_redux/PostSlice';
import store from '../../05_redux/store';
import Emotion from './Emotion';
import HomeworkAssign from './HomeworkAssign';


const NewPostWizard = () =>{
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { homeworkId } = useParams();
    const dispatch = store.dispatch;

    const [ idx, setIdx ] = useState(0);
    const [feelLike, setFeelLike] = useState(-1);
    const [emotions, setEmotions] = useState([] as string[]);
    const [comment, setComment] = useState("");
    // const [images, setImages] = useState([] as string[]);
    const [homework, setHomework] = useState(homeworkId);
    // const [records, setRecords] = useState([] as string[]);
    const images:string[] = [];
    const records:string[] = [];

    useEffect(()=>{
        dispatch(requestHomeworks())
    },[dispatch])

    const saveEnable = feelLike>=0 || emotions.length>0 || comment.length>3 || images.length>0;  

    const save = () => {
        dispatch(newPost({
          comment, 
          feelLike, 
          emotions, 
          images, 
          records, 
          homework: homework ? {"__type":"Pointer",className:"Homework", objectId:homework} : undefined
        })).then(res=>{
          navigate("/")
        })
    }

    const next = () => {
        setIdx(idx+1);
    }

    const back = () => {
        setIdx(idx-1);
    }

    const cancel = () => {
        navigate("/")
    }

    

    const pages = [
        (
            {title:t('newPost.feelLikeTitle'), content:<FeelLikeSelector feelLike={feelLike} setFeelLike={setFeelLike} />}
        ),( 
            {title:t('newPost.emotionTitle'), content:<Emotion emotions={emotions} setEmotions={setEmotions}/>}
        ),(
            {title:t('newPost.commentTitle'), content:<Comment comment={comment} setComment={setComment} />}
        ),(
            {title:t('newPost.homeworkTitle'), content:<HomeworkAssign homework={homework} setHomework={setHomework}/>}
        // ),(
        //     {title:t('newPost.photoTitle'), content:<Photo images={images} setImages={setImages} />} 
        )
    ]

    return(        
        <Carousel swipeable centered autoScroll fullscreen index={idx}> 
            {pages.map((p,i)=>(
                <WizardPage 
                    key={i}
                    save={save} 
                    next={next} 
                    back={back} 
                    setPage={setIdx}
                    cancel={cancel}
                    saveEnable={saveEnable}
                    wizardProgress={i/(pages.length-1)}
                    wizardMax={pages.length}
                    title={p.title}
                >
                    {p.content}
                </WizardPage>
            ))}
        </Carousel> 
    )
}

export default NewPostWizard;
