import React, { useEffect } from 'react';
import { LazyList, ListItem, Page } from 'react-onsenui';
import { useSelector } from 'react-redux';
import { requestPosts } from '../05_redux/PostSlice';
import store, { RootState } from '../05_redux/store';
import { UserType } from '../app/types';
import Post from '../03_organisms/Post/Post';

import { PostEntity } from '../06_utils/db/entity/postEntity';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import TopBar from '../01_atoms/TopBar';

const Wall = ({selectedKid}:Props) =>{

  const {t} = useTranslation();
  const dispatch = store.dispatch;
  const user = useSelector((root:RootState)=>root.statusSlice.status.user)
  let posts:PostEntity[] = useSelector((root:RootState)=>root.postSlice.postStore.posts);

  const editable = user.type==='kid';

  useEffect(()=>{
    dispatch(requestPosts(selectedKid));
  },[dispatch, selectedKid])

  return(
    <Page modifier={isMobile ? 'main' : 'main-browser'}>
      <TopBar title={t('wall.title')}/>
      <div style={{height: 100}}>
        <LazyList
          modifier="no-backgroud"
          length={posts?.length || 0}
          calculateItemHeight={() => 44}
          renderRow={(index) =>
            <ListItem key={index} modifier="nodivider">
              <Post post={posts[index]} editable={editable} terapist={user?.type==='terap'}/>
            </ListItem>
          }
        />
      </div>
    </Page>
  )
}

interface Props{
  selectedKid?: UserType;
}

export default Wall;
