import React from 'react';
import { FaFaceAngry, FaFaceGrinStars, FaFaceMeh } from 'react-icons/fa6';
import classes from './FeelLike.module.scss'


export const feelLikeOptions = [
    {
        icon: <FaFaceGrinStars />,
        style: classes.FeelLikeIconJoy,
        label: 'joy'
    },{
        icon: <FaFaceMeh />,
        style: classes.FeelLikeIconMeh,
        label: 'meh'
    },{
        icon: <FaFaceAngry />,
        style: classes.FeelLikeIconSad,
        label: 'sad'
    }
]

export const FeelLikeIcon = ({feelLike, color}) =>{
    if(feelLike>=0 && feelLike<feelLikeOptions.length){
        return <span className={feelLikeOptions[feelLike].style}>{feelLikeOptions[feelLike].icon}</span>
    }else{
        return <></>
    }
}
