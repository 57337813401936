import {Entity, PrimaryGeneratedColumn, Column, ManyToOne} from "typeorm"; 
import { PostEntity } from "./postEntity";

@Entity('record') 
export class RecordEntity {  
  
  constructor(value){
    this.base64 = value;
  }

  @PrimaryGeneratedColumn()
  id!: number; 

  @ManyToOne(() => PostEntity, (post) => post.records)
  post!: PostEntity

  @Column('blob')
  base64: Buffer;
}