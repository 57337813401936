import React from 'react';
import { Input } from 'react-onsenui';
import classes from './Deadline.module.scss';

const HomeworkDate = ({homeworkDate, setHomeworkDate}: Props) =>{

    return(
        <div className={classes.DeadlineContainer}>
            <Input 
                id="deadline-new-homework--input"
                type="date" 
                className={classes.DeadlineInput}
                value={homeworkDate} 
                onChange={(event) => {setHomeworkDate(event.target.value)} }
            />
        </div>
    )
}

interface Props{
    homeworkDate: Date,
    setHomeworkDate: Function
}

export default HomeworkDate;