import React from 'react';
import emotionsList from '../00_assets/emocje.json';
import { useTranslation } from 'react-i18next';
import classes from './Emotion.module.scss';

const Emotion = ({emotion}:Props) =>{
    const {t} = useTranslation()

    const getEmotionLabel = (e) =>{
        return e.split(":")[0];
    }

    const getEmotionValue = (e) =>{
        return Number(e.split(":")[1]) || 50;
    }

    return(
        <span className={classes.Emotion} style={{'backgroundColor':emotionsList.find(el=>el.name===emotion)?.color}}>
            {t('emotions.'+getEmotionLabel(emotion))} : {getEmotionValue(emotion)}%
        </span>
    )
}

interface Props{
    emotion: string;
}

export default Emotion