import { createSlice } from '@reduxjs/toolkit';
import Redux from 'redux';
import api from '../app/api';


export const ApiSlice = createSlice({
  name: 'userData',
  initialState: {
    api: {
      example: null
    },
  },
  reducers: {
    setResponse: ({api}, {payload}) => {
      api.example = payload;
    },
  },
})

export const uploadFile = (name:string, file:any, extencion:string)=>{
  const config = {
    headers:{
      "Content-Type": "image/"+extencion
    }
  };
  return (dispatch:Redux.Dispatch) =>{
    return new Promise((resolve) =>{
      api.post('/files/'+name, file, config).then((response:any) =>{
        console.log(response);
        // dispatch(setResponse(response.data.results))
        resolve(response.data);
        return response.data;
      }).catch((error:any) =>{
        console.log(error)
        // dispatch(setResponse(error))
        return error;
      });
    });
  }
}

export const { setResponse } = ApiSlice.actions

export default ApiSlice.reducer
