import React from "react";
import { Button, CarouselItem, Col, Row } from "react-onsenui";
import Wave from "../01_atoms/Wave";
import classes from "./WizardPage.module.scss";
import { useTranslation } from "react-i18next";
import { FaX } from "react-icons/fa6";
import Pagination from "../01_atoms/Pagination";
import TopBar from "../01_atoms/TopBar";

const WizardPage = ({back, next, setPage, save, cancel, saveEnable, wizardProgress, wizardMax, title, children}:Props) =>{
    const {t} = useTranslation()

    return(
        <CarouselItem> 
            <Wave/>
            <TopBar fun={cancel} title={""} icon={<FaX/>} withBackground={false}/>
            <div className={classes.WizardPageContent}>
                <Row>
                    <Col>
                        <h1 className={classes.Title}>{title}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>                    
                        {children}
                    </Col>
                </Row>
            </div>
            <div className={classes.WizardPageBottomProgressBar}>
                <Pagination progress={wizardProgress} max={wizardMax} setPage={setPage} next={next} back={back}/>
            </div>
            <Row className={classes.WizardPageBottom}>  
                <Col className={classes.WizardPageBottomCol}>
                    <Button modifier="large--cta" onClick={save} disabled={!saveEnable}>
                        {t("btn.save")}
                    </Button>
                </Col>
            </Row>
        </CarouselItem> 
    )
}

interface Props{
    back: Function,
    next: Function,
    setPage: Function,
    save: Function,
    cancel: Function,
    saveEnable: boolean,
    wizardProgress: number,
    wizardMax: number,
    title: string,
    children: string | JSX.Element | JSX.Element[] 
}

export default WizardPage;
