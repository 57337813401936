import { PostEntity } from '../entity/postEntity';
import { ImageEntity } from '../entity/imageEntity';
import { RecordEntity } from '../entity/recordEntity';
import { HomeworkEntity } from '../entity/homeworkEntity ';
import { DataSource } from 'typeorm';
import { AddPostTable1703416999000 } from '../migration/1703416999000-AddPostTable';
import { AddImageTable1708381240063 } from '../migration/1708381240063-AddImageTable';
import { AddRecordTable1708381240063 } from '../migration/1708381240063-AddRecordTable';
import { AddHomeworkTable1703417999000 } from '../migration/1703417999000-AddHomeworkTable';
import sqliteConnection from '../database';

const dataSource = new DataSource({
  name: 'homeworkConnection',
  type: 'capacitor',
  driver: sqliteConnection,
  database: 'slonieDb',
  entities: [PostEntity, ImageEntity, RecordEntity, HomeworkEntity],
  migrations: [AddPostTable1703416999000, AddImageTable1708381240063, AddRecordTable1708381240063, AddHomeworkTable1703417999000],
  logging: ['error', 'query', 'schema'],
  synchronize: false,
  migrationsRun: false,
});

export default dataSource


