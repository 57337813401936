import React, { useEffect } from 'react';
import classes from './HomeworkAssign.module.scss';
import store, { RootState } from '../../05_redux/store';
import { HomeworkEntity } from '../../06_utils/db/entity/homeworkEntity ';
import { useSelector } from 'react-redux';
import { requestHomeworks } from '../../05_redux/HomeworkSlice';
import { Select } from 'react-onsenui';

const HomeworkAssign = ({homework, setHomework}: Props) =>{
    const dispatch = store.dispatch;
    const homeworks:HomeworkEntity[] = useSelector((root:RootState)=>root.homeworkSlice.homeworkStore.homeworks);


    useEffect(()=>{
        dispatch(requestHomeworks())
    },[dispatch])
    
    return(
        <div className={classes.commentContainer}>
            <Select modifier="homework"
              id="homework--select"
              value={homework}
              defaultValue={homework}
              onChange={(event) => setHomework(event.target.value)}>
              <option value={""}>-</option>
              {homeworks.map((h,i)=><option key={i} value={h.id}>{h.title}</option>)}
            </Select>
        </div>
    )
}

interface Props{
    homework: string,
    setHomework: Function
}

export default HomeworkAssign;
