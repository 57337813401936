import {MigrationInterface, QueryRunner} from "typeorm";

export class AddHomeworkTable1703417999000 implements MigrationInterface {

	public async up(queryRunner: QueryRunner): Promise<void> {
		await queryRunner.query(`
            CREATE TABLE IF NOT EXISTS "homework" (
                "id" integer PRIMARY KEY AUTOINCREMENT NOT NULL,
                "objectId" varchar,
                "title" varchar,
                "descr" varchar,
                "archived" boolean,
                "rating" integer,
                "versionLocal" integer,
                "homeworkDate" datetime
            );
        `);
	}

	public async down(queryRunner: QueryRunner): Promise<void> {
		await queryRunner.query(`
            DROP TABLE "homework";
        `);
	}
}