import React, { useEffect } from 'react';
import {  LazyList, ListItem, Page } from 'react-onsenui';
import { useSelector } from 'react-redux';
import { requestHomeworks } from '../05_redux/HomeworkSlice';
import store, { RootState } from '../05_redux/store';
import { HomeworkEntity } from '../06_utils/db/entity/homeworkEntity ';
import { isMobile } from 'react-device-detect';
import Homework from '../03_organisms/Homework/Homework';
import { useTranslation } from 'react-i18next';
import TopBar from '../01_atoms/TopBar';
import Separator from '../01_atoms/Separator';

const HomeWorks = () =>{
  const dispatch = store.dispatch;
  const {t} = useTranslation();
  const selectedKid = useSelector((root:RootState)=>root.statusSlice.status.selectedKid);

  const homeworks:HomeworkEntity[] = useSelector((root:RootState)=>root.homeworkSlice.homeworkStore.homeworks);
  const homeworksToDO = homeworks.filter(h=>!h.archived);
  const homeworksDone = homeworks.filter(h=>h.archived);

  useEffect(()=>{
    dispatch(requestHomeworks(selectedKid));
  },[dispatch, selectedKid]);

  return(
    <Page modifier={isMobile ? 'main' : 'main-browser'}>
      <TopBar title={t('homeworks.title')}/>
      <div style={{height: 100}}>
        <LazyList
          modifier="no-backgroud"
          length={homeworksToDO?.length || 0}
          calculateItemHeight={() => 44}
          renderRow={(index) => {
            const homework = homeworksToDO[index]
            return(
              <ListItem key={index} modifier="nodivider">
                <Homework homework={homework}/>
              </ListItem>
          )}}
        />
        {homeworksDone?.length >0 &&
          <>
            <Separator title={t('homeworks.separator')}/>
            <LazyList
              modifier="no-backgroud"
              length={homeworksDone?.length || 0}
              calculateItemHeight={() => 44}
              renderRow={(index) => {
                const homework = homeworksDone[index]
                return(
                  <ListItem key={index} modifier="nodivider">
                    <Homework homework={homework}/>
                  </ListItem>
              )}}
            />          
          </>
        }
      </div>
    </Page>
  )
}


export default HomeWorks;
