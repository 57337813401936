import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-onsenui';
import WizardPage from '../../02_molecule/WizardPage';
import { useTranslation } from 'react-i18next';
import FeelLikeSelector from './FeelLikeSelector';
import Comment from './Comment';
import { useNavigate, useParams } from 'react-router-dom';
import { requestHomeworks } from '../../05_redux/HomeworkSlice';
import { updatePost } from '../../05_redux/PostSlice';
import store, { RootState } from '../../05_redux/store';
import Emotion from './Emotion';
import HomeworkAssign from './HomeworkAssign';
import { useSelector } from 'react-redux';


const EditPostWizard = () =>{
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { postId } = useParams();
    const dispatch = store.dispatch;

    const post = useSelector((root:RootState)=>root.postSlice.postStore.posts).find(p=>p.id===Number(postId));

    const [idx, setIdx] = useState(0);
    const [feelLike, setFeelLike] = useState(post?.feelLike);
    const [emotions, setEmotions] = useState(post?.emotions);
    const [comment, setComment] = useState(post?.comment);
    // const [images, setImages] = useState([] as string[]);
    const [homework, setHomework] = useState(post?.homework);
    // const [records, setRecords] = useState([] as string[]);
    const images:string[] = [];
    const records:string[] = [];

    useEffect(()=>{
        dispatch(requestHomeworks())
    },[dispatch])

    const saveEnable = feelLike>=0 || emotions.length>0 || comment.length>3 || images.length>0;  

    const save = () => {
        dispatch(updatePost({
          id:post?.id, 
          comment, 
          feelLike, 
          emotions, 
          images, 
          records, 
          postDate: post?.postDate,
          homework: homework ? {"__type":"Pointer",className:"Homework", objectId:homework} : undefined
        })).then(res=>{
          navigate("/post/"+post?.id);
        })
    }

    const next = () => {
        setIdx(idx+1);
    }

    const back = () => {
        setIdx(idx-1);
    }

    const cancel = () => {
        navigate("/post/"+post?.id);
    }

    

    const pages = [
        (
            {title:t('newPost.feelLikeTitle'), content:<FeelLikeSelector feelLike={feelLike} setFeelLike={setFeelLike} />}
        ),( 
            {title:t('newPost.emotionTitle'), content:<Emotion emotions={emotions} setEmotions={setEmotions}/>}
        ),(
            {title:t('newPost.commentTitle'), content:<Comment comment={comment} setComment={setComment} />}
        ),(
            {title:t('newPost.homeworkTitle'), content:<HomeworkAssign homework={homework} setHomework={setHomework}/>}
        // ),(
        //     {title:t('newPost.photoTitle'), content:<Photo images={images} setImages={setImages} />} 
        )
    ]

    return(        
        <Carousel swipeable centered autoScroll fullscreen index={idx}> 
            {pages.map((p,i)=>(
                <WizardPage 
                    key={i}
                    save={save} 
                    next={next} 
                    back={back} 
                    setPage={setIdx}
                    cancel={cancel}
                    saveEnable={saveEnable}
                    wizardProgress={i/(pages.length-1)}
                    wizardMax={pages.length}
                    title={p.title}
                >
                    {p.content}
                </WizardPage>
            ))}
        </Carousel> 
    )
}

export default EditPostWizard;
