import {Entity, PrimaryGeneratedColumn, Column, OneToMany, ManyToOne} from "typeorm"; 
import type { DataBlob, PostType } from "../../../app/types";
import { ImageEntity } from './imageEntity';
import { RecordEntity } from "./recordEntity";
import { HomeworkEntity } from "./homeworkEntity ";

@Entity('post') 
export class PostEntity {  
  
  constructor(post: PostType, homeworks:HomeworkEntity[]){
    this.id = post?.id;
    this.objectId = post?.objectId;
    this.comment = post?.comment;
    this.emotions = post?.emotions?.join(';');
    this.feelLike = post?.feelLike;
    this.versionLocal = 0;
    if(post?.postDate){
      this.postDate = new Date(post.postDate);
    }else if(post?.createdAt){
      this.postDate = new Date(post.createdAt);
    }else{
      this.postDate = new Date();
    }
    this.images = !!(post?.images?.length) ? post.images?.map(i=>({base64:i})) : undefined;
    this.records = !!(post?.records?.length) ? post.records?.map(i=>({base64:i})) : undefined;

    this.homework = !!post?.homework? homeworks?.find(h=>(post.homework.objectId === h.objectId || post.homework.objectId === ""+h.id)) : undefined;
    
    this.ACL = post?.ACL;
    this.sentiment = post?.sentiment;
  }

  @PrimaryGeneratedColumn()
  id!: number; 

  @Column('text')
  objectId: string | undefined;

  @Column('text')
  comment: string; 
  
  @Column('text')
  emotions: string; 

  @Column('integer')
  feelLike: number; 

  @Column('integer')
  versionLocal: number; 

  @Column({
    type: 'datetime',
    transformer: {
        from(value: 'datetime') {
            return new Date(value);
        },
        to(value) {
            return value
        }
    }
  })
  postDate!: Date; 

  @OneToMany(
    (type)=>ImageEntity,
    (blob) => blob.post,
    {  cascade: true, eager: true }  
  )
  images: DataBlob[] | undefined;

  @OneToMany(
    (type)=>RecordEntity,
    (blob) => blob.post,
    { cascade: true, eager: true }  
  )
  records: DataBlob[] | undefined;

  @ManyToOne(() => HomeworkEntity, (homework) => homework.post)
  homework!: HomeworkEntity | undefined


  sentiment?: string;
  ACL?:any;
}

