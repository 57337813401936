import { createSlice } from '@reduxjs/toolkit';
import Redux from 'redux';
import api from '../app/api';
import { Preferences } from '@capacitor/preferences';
import store from './store';
import bcrypt from 'bcryptjs'

const salt = "$2a$10$zjmxrVbWMUnuNqEsk3WV/.";

export const StatusSlice = createSlice({
  name: 'userData',
  initialState: {
    status: {
      user: {}, 
      selectedKid: null as any,
      availableKids: [] as any[],
      loading: false,
      isOnline: process.env.REACT_APP_ONLINE==='true' || false,
      pin: null
    },
  },
  reducers: {
    setUser: ({status}, {payload}) => {
      status.user = payload;   
      sessionStorage.setItem('user', JSON.stringify(payload));
      if(!payload.offline){
        Preferences.set({ key: 'user', value: JSON.stringify(payload) });
      }
    },
    setSelectedKid: ({status}, {payload}) => {
      status.selectedKid = payload;
    },
    setAvailableKids: ({status}, {payload}) => {
      status.availableKids = payload.filter(p=>p.type==='kid' || p.type==='parent');
    },
    setLoading: ({status}, {payload}) => {
      status.loading = payload;
    },
    setPin: ({status}, {payload}) => {
      status.pin = payload;
    },
    setPinAndPersist: ({status}, {payload}) => {
      const hashedPin = bcrypt.hashSync(JSON.stringify(payload), salt)
      status.pin = hashedPin;
      Preferences.set({ key: 'pin', value: hashedPin });
    }
  },
})


export const loginOffline = (pin: number[])=>{
  return (dispatch:Redux.Dispatch) =>{
    return new Promise((resolve, reject) =>{    
      if(bcrypt.hashSync(JSON.stringify(pin), salt) === store.getState().statusSlice.status.pin){
        dispatch(setUser({type:'kid', objectId:"offline", offline:true}))
        resolve(true);
      }else{
        reject(false)
      }
    })
  }
}

export const login = (username:string, password:string)=>{
  return (dispatch:Redux.Dispatch) =>{
      api.post('/login', {"username":username,"password":password}).then((response:any) =>{
        dispatch(setUser(response.data))
        window.location.reload();
      }).catch((error:any) =>{
        console.log(error)
      });
  }
}

export const validate = ()=>{
  return (dispatch:Redux.Dispatch) =>{
    return new Promise((resolve, reject) =>{
      api.get('/users/me',{
        headers: {
          'X-Parse-Session-Token': JSON.parse(sessionStorage.getItem("user")||"{}")?.sessionToken
        }
      }).then((response:any) =>{
        resolve(response.data);
        return response.data;
      }).catch((error:any) =>{
        reject(error)
        return error;
      });
    });
  }
}

export const logout = ()=>{
  return (dispatch:Redux.Dispatch) =>{
    return new Promise((resolve, reject) =>{
      api.post('/logout',{
        headers: {
          'X-Parse-Session-Token': JSON.parse(sessionStorage.getItem("user")||"{}")?.sessionToken
        }
      }).then((response:any) =>{
        dispatch(setUser(null))
        resolve(response.data);
        return response.data;
      }).catch((error:any) =>{
        dispatch(setUser(null))
        return error;
      });
    });
  }
}

export const requestAvailableKids = ()=>{
  return (dispatch:Redux.Dispatch) =>{
    return new Promise((resolve) =>{
      api.get('/users').then((response:any) =>{
        dispatch(setAvailableKids(response.data.results))
        resolve(response.data);
        return response.data;
      }).catch((error:any) =>{
        console.log(error)
        dispatch(setAvailableKids([]))
        return error;
      });
    });
  }
}

export const { setUser, setSelectedKid, setAvailableKids, setLoading, setPin, setPinAndPersist } = StatusSlice.actions

export default StatusSlice.reducer
