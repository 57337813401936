import React from 'react';
import classes from './Fab.module.scss';

const Fab = ({children, onClick, title, floating=true, disabled=false}:Props) =>{

    const styling = [floating? classes.FabContainerFloating : classes.FabContainer]

    if(disabled){
        styling.push(classes.FabDisabled)
    }

    return(
        <>
            <div className={styling.join(" ")} onClick={onClick}>
                <div className={classes.Fab}>{children}</div>
            </div>
            <div className={classes.FabTitle}>{title && <div>{title}</div>}</div>
        </>
    )
}

interface Props{
    children: JSX.Element,
    onClick: Function,
    title: string,
    floating: boolean,
    disabled: boolean
}

export default Fab;