import React, { useMemo } from 'react';
import { Button, Col, Row } from 'react-onsenui';
import { FaCircle, FaRegCircle } from "react-icons/fa6";

const PinSelector = ({title, value, onChange, randomize, error}:Props) =>{
    const numBtns = useMemo(()=>randomize ? [0,1,2,3,4,5,6,7,8,9].sort(v=>.5 - Math.random()) : [0,1,2,3,4,5,6,7,8,9], [randomize]);

    const button = (v) =>{
        const btnValue = numBtns[v];
        return <Button id={"pin--btn-"+btnValue} onClick={()=>onChange(btnValue)} modifier='pinselector'>{btnValue}</Button>
    }

    return(
        <div className='pinselector-container'>
            <h1>{title}</h1>
            <div className='pinselector-value'>
                <Row>
                    <Col width="25%">{value[0] !== undefined ? <FaCircle/> : <FaRegCircle/>}</Col>
                    <Col width="25%">{value[1] !== undefined ? <FaCircle/> : <FaRegCircle/>}</Col>
                    <Col width="25%">{value[2] !== undefined ? <FaCircle/> : <FaRegCircle/>}</Col>
                    <Col width="25%">{value[3] !== undefined ? <FaCircle/> : <FaRegCircle/>}</Col>
                </Row>
            </div>
            {error?.length>0 && <font className='error'>{error}</font>}
            <div className='pinselector'>
                <Row>
                    <Col width="33%">{button(1)}</Col>
                    <Col width="33%">{button(2)}</Col>
                    <Col width="33%">{button(3)}</Col>
                </Row>
                <Row>
                    <Col width="33%">{button(4)}</Col>
                    <Col width="33%">{button(5)}</Col>
                    <Col width="33%">{button(6)}</Col>
                </Row>
                <Row>
                    <Col width="33%">{button(7)}</Col>
                    <Col width="33%">{button(8)}</Col>
                    <Col width="33%">{button(9)}</Col>
                </Row>
                <Row>
                    <Col width="33%"></Col>
                    <Col width="33%">{button(0)}</Col>
                    <Col width="33%"></Col>
                </Row>
            </div>
        </div>
    )
}

interface Props{
    title: string;
    value: number[];
    onChange: Function;
    randomize: boolean;
    error: string;
}

export default PinSelector;