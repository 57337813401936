import {Entity, PrimaryGeneratedColumn, Column, OneToMany} from "typeorm"; 
import type { HomeworkType } from "../../../app/types";
import { PostEntity } from "./postEntity";

@Entity('homework') 
export class HomeworkEntity {  
  
  constructor(homework: HomeworkType){
    this.objectId = homework?.objectId;
    this.title = homework?.title;
    this.descr = homework?.descr;
    this.archived = homework?.archived;
    this.rating = homework?.rating;
    this.versionLocal = 0;
    if(homework?.homeworkDate){
      this.homeworkDate = new Date(homework.homeworkDate);
    }else if(homework?.createdAt){
      this.homeworkDate = new Date(homework.createdAt);
    }else{
      this.homeworkDate = new Date();
    }
  }

  @PrimaryGeneratedColumn()
  id!: number; 

  @Column('text')
  objectId: string | undefined;

  @Column('text')
  title: string; 
  
  @Column('text')
  descr: string; 
  
  @Column('boolean')
  archived: boolean; 

  @Column('integer')
  rating: number; 

  @Column('integer')
  versionLocal: number; 

  @Column({
    type: 'datetime',
    transformer: {
        from(value: 'datetime') {
            return new Date(value);
        },
        to(value) {
            return value
        }
    }
  })
  homeworkDate!: Date; 

  @OneToMany(
    (type)=>PostEntity,
    (post) => post.homework,
    { eager: true }  
  )
  post: PostEntity[] | undefined;
}

