import { Capacitor } from "@capacitor/core";
import api from "../app/api";
import { PostType } from "../app/types";
import PostDataSource from "./db/data-sources/PostDataSource";
import { PostEntity } from "./db/entity/postEntity";
import sqliteConnection from './db/database';
import store from "../05_redux/store";
import { homework2HomeworkType } from "./mergeHomeworks";

export const mergePosts = async(localPosts:PostEntity[], remotePosts:PostType[], userObjectId:string, dispatch:Function):Promise<PostType[]> =>{
    return new Promise(async (resolve) =>{
        const newLocalPost:PostType[] = [];
        const connection = PostDataSource;
        const database = connection.options.database;
        const postRepository = connection.getRepository(PostEntity);
        const homeworks = store.getState().homeworkSlice.homeworkStore.homeworks;

        //find post that are local but are not on remote
        await localPosts.filter(p=>!p.objectId).forEach(async (p:PostEntity) => {
            const ACL = {}
            ACL[userObjectId] = { "read": true, "write": true }
            const dataToSend:PostType = {
                ...post2PostType(p), 
                ACL, 
                owner:{"__type":"Pointer",className:"_User",userObjectId}
            }
            const newRemotePostObjectId = (await api.post('/classes/Post', dataToSend )).data.objectId;

            let postToUpdate = await postRepository.findOne({ where: { id: p.id } });
            if (postToUpdate != null) {
                postToUpdate.objectId = newRemotePostObjectId;
                await postRepository.save(postToUpdate);
            }

            if (Capacitor.getPlatform() === 'web'&& typeof database === 'string') {        
                await sqliteConnection.saveToStore(database);
            }
        });

        //find post that are remote but not local
        const postsToSave:PostEntity[] = []
        await remotePosts.filter(p=>!localPosts.find(pp=>pp.objectId === p.objectId)).forEach(async (p:PostType)=>{
            const post:PostEntity = new PostEntity(p, homeworks);
            postsToSave.push(post);
            newLocalPost.push(p)       
        })
        if(postsToSave.length>0){
            await postRepository.save(postsToSave);
            if (Capacitor.getPlatform() === 'web'&& typeof database === 'string') {        
                await sqliteConnection.saveToStore(database);
            }
        }

        resolve(newLocalPost);
    })
}

export const posts2PostType = (posts:PostEntity[]):PostType[] =>{
    return posts.map(p=>post2PostType(p));
}

export const post2PostType = (post:PostEntity):PostType =>{
    return {
        id: post.id,
        objectId: post.objectId,
        comment: post.comment,
        feelLike: post.feelLike,
        emotions: post.emotions?.split(';'),
        images: post.images?.map(i=>i.base64),
        records: post.records?.map(i=>i.base64),
        homework: post.homework && homework2HomeworkType(post.homework),
        postDate: post.postDate
    }
}