import React from 'react';
import NewPin from "../03_organisms/NewPin";


const Options = () =>{

    return(
        <section>
            <NewPin/>
        </section>
    )
}

export default Options;