import React from 'react';
import classes from './FeelLikeSelector.module.scss'
import { FaFaceAngry, FaFaceGrinStars, FaFaceMeh } from 'react-icons/fa6';

const FeelLikeSelector = ({feelLike, setFeelLike}:Props) =>{

    const options = [
        <FaFaceGrinStars />,
        <FaFaceMeh />,
        <FaFaceAngry />
    ]

    return(
        <div className={classes.feellikeselectorContainer}>
            {options.map((o,i)=>(
                <span 
                    key={i} 
                    className={feelLike === i ? classes.feellikeselectorOptionCheck : classes.feellikeselectorOption}
                    onClick={()=>setFeelLike(i)}
                >
                    {o}
                </span>
            ))}
        </div>
    )
}

interface Props{
    feelLike: number, 
    setFeelLike: Function
}

export default FeelLikeSelector;