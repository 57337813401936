import React from 'react';
import classes from './TopBar.module.scss';
import { Button, Col, Row } from 'react-onsenui';
import { FaArrowLeft } from 'react-icons/fa6';
import { JsxElement } from 'typescript';

const TopBar = ({fun, title, icon, withBackground=true}: Props) =>{

    return(
        <Row className={[classes.TopBarContainer, withBackground && classes.TopBarContainerBackground].join(" ")}>
            <Col width="10">
                {fun && 
                    <Button className={classes.BackBtn} modifier="light" onClick={fun}>
                        {icon ? icon : <FaArrowLeft/>}
                    </Button>
                } 
            </Col>
            <Col width="80" className={classes.Title}>
                {title}
            </Col>
            <Col width="10"/>
        </Row>

    )
}

interface Props{
    fun?: Function,
    title: string,
    icon: JsxElement,
    withBackground: boolean
}

export default TopBar;