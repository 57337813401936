import React from 'react';
import './App.scss';
import { Modal, Page } from 'react-onsenui';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../05_redux/store';
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';


const Layout = ({menu, disableMenu, user}: React.PropsWithChildren<Props>) =>{
  const status = useSelector((root:RootState)=>root.statusSlice.status);

  return (
    <Page className="bg">
      <Outlet/>
      {/* <img src={bg} className='bg' alt=""/> */}

      <Modal isOpen={status.loading}>
        Loading ...
      </Modal>
      <AddToHomeScreen 
        skipFirstVisit={false} 
        cookie={{name:"slonie-install-promp"}}
      />
    </Page>
  );
}

interface Props{
  menu:AppLink[],
  disableMenu: boolean;
  user?: any
}

interface AppLink{
  label: string;
  href: string;
  icon: Element;
}

export default Layout;
