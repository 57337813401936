import React from 'react';
import classes from './AddHomeworkManualy.module.scss'
import { useTranslation } from 'react-i18next';

const AddHomeworkManualy = ({title, descr, setTitle, setDescr}: Props) =>{
    const {t} = useTranslation()

    return(
        <div className={classes.commentContainer}>
            <textarea
                id="title-new-homework--input"
                className="textarea"
                value={title}
                rows={1}
                onChange={(event) => { setTitle(event.target.value)} }
                placeholder={t('newHomework.titlePlaceholder')}
                float
            />
            <textarea
                id="descr-new-homework--input"
                className="textarea"
                value={descr}
                rows={10}
                placeholder={t('newHomework.descrPlaceholder')}
                onChange={(event) => { setDescr(event.target.value)} }
            />
        </div>
    )
}

interface Props{
    title: string,
    descr: string,
    setTitle: Function
    setDescr: Function
}

export default AddHomeworkManualy;